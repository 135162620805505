import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import fileHandling, {
  canOpenFileInNewWindow,
  componentsFromFileName,
  confirmUniqueName,
  FileData
} from "@fd/lib/vue/mixins/fileHandling";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";

type Keyword = Tag;

export function GetIsoDetails(iso: IsoSearchResult): string {
  let values = [];
  if (iso.systemName && iso.systemName.trim().length > 0) {
    values.push(`${iso.systemName.trim()}`);
  }

  if (iso.testPackageName && iso.testPackageName.trim().length > 0) {
    values.push(`${iso.testPackageName.trim()}`);
  }
  return values.join(", ");
}

import {
  Tag,
  ScaffoldRequestTypes,
  scaffoldRequestTypeHelper,
  scaffoldRequestSubTypeHelper,
  ScaffoldDesignWithDetails,
  scaffoldDesignService,
  projectLocationService,
  ProjectLocation,
  ExternalLink,
  externalLinkService,
  scaffoldService,
  ScaffoldSearchResult,
  ContractorPurchaseOrder,
  contractorService,
  ContractorWithTags,
  Discipline,
  disciplineService,
  Person,
  personService,
  ScaffoldRequestTypeDetails,
  ScaffoldRequestSubTypeWithParent,
  PersonWithParsedChildIDs,
  JobWithData,
  JobTypes,
  JobStatuses,
  IsoSearchResult,
  isoService
} from "../../../services";

import { jobService, workPackageService } from "../../../services";
import { Attachment } from "../../../dataMixins/attachment";
import { openExternalLinkDetails } from "../ExternalLinkDialog.vue";
import { showTextPromptDialog } from "../../../../../common/client/views/components/TextPromptDialog.vue";
import { filterByContractors } from "../../../services/taggableItems";
import { GetPersonName, HasName, SortItemsWithName } from "../../../utils/person";
import { BasicSelectItem } from "@fd/lib/vue/utility/select";
import { valueInArray } from "@fd/lib/client-util/array";
import {
  GetScaffoldDescription,
  GetScaffoldDetails,
  WorkPackageWithNameCode
} from "../../../dataMixins/scaffoldRequest";
import rules from "../../../../../lib/vue/rules";
import userAccess from "../../../dataMixins/userAccess";
import VueI18n from "vue-i18n";
import { showItemSelectionBottomDialog } from "../ItemSelectionBottomDialog.vue";
import { job } from "../../../dataMixins/job";

type FormattedScaffoldDesign = ScaffoldDesignWithDetails & {
  formattedScaffoldNumber: string;
};
export type JobWithChildIDsAndArchived = JobWithData & {
  archived: boolean;
};

const JobNewDialog = FDVue.extend({
  name: "sp-job-new-dialog",

  mixins: [dialogSupport, fileHandling, job],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-async-search-box": () => import("@fd/lib/vue/components/AsyncSearchBox.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue"),
    "fd-subheader": () => import("@fd/lib/vue/components/layout/Subheader.vue"),
    "fd-work-package-selector": () => import("../WorkPackageSelectionDialog.vue"),
    "sp-work-order-scope-details-form": () => import("../forms/SP.WorkOrderScopeDetailsForm.vue")
  },

  data: function() {
    return {
      /*** GLOBAL ****/
      hideActionButtons: false,
      step: 1,
      lastStep: 4,

      // Form data errors
      basicStepError: false,
      photosStepError: false,
      attachmentsStepError: false,
      basicStep: 1,
      scopeStep: 2,
      photosStep: 3,
      attachmentsStep: 4,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      releasing: false,

      /*** DATA ***/
      // Reference
      allRequestTypes: [] as ScaffoldRequestTypeDetails[],
      allRequestSubTypes: [] as ScaffoldRequestSubTypeWithParent[],
      contractors: [] as ContractorWithTags[],
      allContractors: [] as ContractorWithTags[],
      allRequestors: [] as (PersonWithParsedChildIDs & HasName)[],
      allDisciplines: [] as Discipline[],
      allVisibleAreas: [] as ProjectLocation[],
      allVisibleSubAreas: [] as ProjectLocation[],

      /*** TAG NUMBER ***/
      availableScaffolds: [] as ScaffoldSearchResult[],
      selectedScaffold: null as ScaffoldSearchResult | null,
      // Determines whether the area/subarea/etc. have been set via scaffold selection
      dataSetByScaffold: false,

      /*** ISO ***/
      availableIsos: [] as IsoSearchResult[],
      selectedIso: null as IsoSearchResult | null,

      /*** Design NUMBER ***/
      availableDesigns: [] as FormattedScaffoldDesign[],
      selectedDesign: null as FormattedScaffoldDesign | null,
      // Determines whether the area/subarea/etc. have been set via ScaffoldDesign selection
      dataSetByDesign: false,

      /*** KEYWORDS ***/
      selectedKeywords: [] as Keyword[],

      /*** IWPs ***/
      availableIWPs: [] as WorkPackageWithNameCode[],
      selectedIWPs: [] as WorkPackageWithNameCode[],

      // Main entity
      job: {
        workPackageIDs: [],
        tagIDs: [],
        relatedWorkOrders: [],
        jobTypeID: JobTypes.Scaffold,
        // currentUserPermissions: {} as SummarizedJobPermissions,
        id: undefined,
        jobStatusID: JobStatuses.Draft,
        internalNumber: undefined,
        requestorID: undefined,
        workOrderNumber: undefined,
        messageThreadID: undefined,
        noteThreadID: undefined,
        submittedBy: undefined,
        submittedOn: undefined,
        jobStatus: undefined,
        jobStatusDetail: undefined,
        jobApprovalIsAutomatic: false,
        workOrderStatus: undefined,
        requestType: undefined,
        requestSubType: undefined,
        scaffoldDesignID: undefined,
        scaffoldID: undefined,
        scaffoldNumber: undefined,
        isoID: undefined,
        jobID: undefined,
        contractorID: undefined,
        disciplineID: undefined,
        requestingEmployeeID: undefined,
        requiredDate: undefined,
        requiredUntilDate: undefined,
        areaID: undefined,
        subAreaID: undefined,
        specificWorkLocation: undefined,
        detailedWorkDescription: undefined,
        siteContact: undefined,
        notes: undefined,
        isClientWorkOrder: false,
        clientWorkOrderReferenceNumber: undefined,
        clientWorkOrderReason: undefined,
        isChangeOrder: false,
        changeOrderReferenceNumber: undefined,
        changeOrderReason: undefined,
        isRework: false,
        reworkReferenceNumber: undefined,
        reworkReason: undefined,
        isServiceOrder: false,
        serviceOrderReferenceNumber: undefined,
        serviceOrderReason: undefined,
        purchaseOrderID: undefined,
        existingTagNumber: undefined,
        tagIDJson: undefined,
        archivedDate: undefined,
        created: undefined,
        createdBy: undefined,
        updated: undefined,
        updatedBy: undefined,
        archived: false
      } as JobWithChildIDsAndArchived,

      // *** PHOTOS & ATTACHMENTS ***
      touchedFileName: "",
      showPhotoTabAttachmentAlert: false,
      showAttachmentTabPhotoAlert: false,
      tablesearchfiles: "",
      allFiles: [] as FileData[],
      externalLinks: [] as ExternalLink[],

      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined
    };
  },

  computed: {
    //#region "Global"
    unwatchedMethodNames() {
      return ["getScopeStepRule", "fileRowClassName", "photosRowClicked", "attachmentRowClicked"];
    },
    requestIsForScaffold(): boolean {
      return this.job.jobTypeID == JobTypes.Scaffold;
    },
    requestIsForMaintenance(): boolean {
      return this.job.jobTypeID == JobTypes.Maintenance;
    },
    requestIsForPaint(): boolean {
      return this.job.jobTypeID == JobTypes.Paint;
    },
    requestIsForInsulation(): boolean {
      return this.job.jobTypeID == JobTypes.Insulation;
    },
    requestIsForHeatTrace(): boolean {
      return this.job.jobTypeID == JobTypes.HeatTrace;
    },
    requestIsForRefractory(): boolean {
      return this.job.jobTypeID == JobTypes.Refractory;
    },
    requestIsForFireproofing(): boolean {
      return this.job.jobTypeID == JobTypes.Fireproofing;
    },
    //#endregion

    //#region "Details"
    canSelectContractor(): boolean {
      return !!this.contractors && this.contractors.length > 1;
    },
    canSelectScaffoldDesign(): boolean {
      return false;
      // return (
      //   this.requestIsForScaffold &&
      //   this.isScaffoldErectJob &&
      //   this.$store.state.curEnvironment.enableScaffoldDesign
      // );
    },
    availableKeywords(): Keyword[] {
      return this.$store.getters.sortedEnabledTags;
    },
    requestTypes(): BasicSelectItem[] {
      return this.allRequestTypes.map(x => ({ text: x.displayName, value: x.value }));
    },
    requestSubTypes(): BasicSelectItem[] {
      console.log(
        `requestSubTypes currentType: ${this.job.requestType}, allRequestSubTypes: ${JSON.stringify(
          this.allRequestSubTypes
        )}`
      );
      return this.allRequestSubTypes
        .filter(x => x.parentRequestType == this.job.requestType)
        .map(x => ({ text: x.displayName, value: x.value }));
    },
    areas(): ProjectLocation[] {
      let contractorID = this.job.contractorID;
      if (!contractorID) return [];

      let selectedContractor = this.contractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      var areas = this.allVisibleAreas.filter(
        x =>
          !!selectedContractor?.includesAllAreas || valueInArray(x.id, selectedContractor?.areaIDs)
      );
      if (!this.job.areaID && areas.length == 1) {
        this.$nextTick(() => {
          this.job.areaID = areas[0].id;
        });
      }
      return areas;
    },
    subAreas(): ProjectLocation[] {
      let areaID = this.job.areaID;
      if (!areaID) return [];

      var subAreas = this.allVisibleSubAreas.filter(x => {
        return x.parentLocationID == areaID;
      });

      if (!this.job.subAreaID && subAreas.length == 1) {
        this.$nextTick(() => {
          this.job.subAreaID = subAreas[0].id;
        });
      }

      return subAreas;
    },
    disciplines(): Discipline[] {
      let contractorID = this.job.contractorID;
      if (!contractorID) return [];

      let selectedContractor = this.contractors.find(x => x.id == contractorID);
      if (!selectedContractor) return [];

      let disciplines = this.allDisciplines.filter(
        x =>
          !!selectedContractor?.includesAllDisciplines ||
          valueInArray(x.id, selectedContractor?.disciplineIDs)
      );

      if (!this.job.disciplineID && disciplines.length == 1) {
        this.$nextTick(() => {
          this.job.disciplineID = disciplines[0].id;
        });
      }

      return disciplines;
    },
    selectedContractorName(): string | undefined {
      return this.contractors.find(x => x.id == this.job.contractorID)?.name;
    },
    requestors(): Person[] {
      if (!this.job.contractorID || !this.job.disciplineID) {
        return [];
      }

      let requestors = this.allRequestors.filter(
        x =>
          !!x.contractorID &&
          x.contractorID == this.job.contractorID &&
          (!x.disciplineIDs?.length || valueInArray(this.job.disciplineID, x.disciplineIDs))
      );

      if (!this.job.requestingEmployeeID) {
        let curUserRequestor = requestors.find(r => r.id == this.curUserID);
        if (!!curUserRequestor) {
          this.job.requestingEmployeeID = curUserRequestor.id;
        } else {
          if (requestors.length == 1) {
            this.$nextTick(() => {
              this.job.requestingEmployeeID = requestors[0].id;
            });
          }
        }
      }

      return requestors;
    },
    //#endregion

    //#region "Scope"
    missingRequiredClientWorkOrderData(): boolean {
      return !!this.job.isClientWorkOrder && !this.job.clientWorkOrderReferenceNumber?.length;
    },
    missingRequiredChangeOrderData(): boolean {
      return !!this.job.isChangeOrder && !this.job.changeOrderReferenceNumber?.length;
    },
    missingRequiredReworkData(): boolean {
      return !!this.job.isRework && !this.job.reworkReferenceNumber?.length;
    },
    missingRequiredServiceOrderData(): boolean {
      return !!this.job.isServiceOrder && !this.job.serviceOrderReferenceNumber?.length;
    },
    missingRequiredPurchaseOrderData(): boolean {
      let enablePurchaseOrders = this.$store.state.curEnvironment.enablePurchaseOrders;
      if (!enablePurchaseOrders) {
        this.job.purchaseOrderID = null;
        return false;
      }

      if (!!this.job.purchaseOrderID?.length) {
        // Verify the selected purchase order is still in the selectable list, since the contractor may have changed.
        this.job.purchaseOrderID = this.selectablePurchaseOrders.find(
          x => x.id == this.job.purchaseOrderID
        )?.id;
      }
      return !this.job.purchaseOrderID?.length;
    },
    scopeStepError(): boolean {
      return (
        this.missingRequiredClientWorkOrderData ||
        this.missingRequiredChangeOrderData ||
        this.missingRequiredReworkData ||
        this.missingRequiredServiceOrderData ||
        this.missingRequiredPurchaseOrderData
      );
    },
    allPurchaseOrders(): ContractorPurchaseOrder[] {
      let allPurchaseOrders = this.$store.state.contractorPurchaseOrders
        .fullList as ContractorPurchaseOrder[];
      let sortedPurchaseOrders = allPurchaseOrders.sort(
        (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
      );
      return sortedPurchaseOrders;
    },
    selectablePurchaseOrders(): ContractorPurchaseOrder[] {
      let contractorID = this.allContractors.find(x => x.id == this.job.contractorID)?.id;
      if (!contractorID?.length) return [];

      return filterByContractors([contractorID], this.allPurchaseOrders);
    },
    //#endregion

    //#region "Photos"
    photoFiles(): FileData[] {
      return this.allFiles.filter(x => x.isPhoto);
    },
    //#endregion

    //#region "Notes"
    //#endregion

    //#region "Attachments"
    nonPhotoAttachments(): Attachment[] {
      let attachments = [] as Attachment[];

      this.allFiles.forEach(file => {
        attachments.push({
          type: "file",
          name: file.name,
          isPhoto: file.isPreviewable ?? false,
          isPreviewable: file.isPreviewable ?? false,
          canOpenInNew: canOpenFileInNewWindow(file.name),
          file: file
        });
      });

      this.externalLinks.forEach(link => {
        attachments.push({
          type: "link",
          name: link.name!,
          isPhoto: false,
          isPreviewable: false,
          canOpenInNew: true,
          link: link
        });
      });

      return attachments.filter(x => !x.isPhoto);
    }
    //#endregion
  },

  methods: {
    ...mapActions({
      loadPurchaseOrders: "LOAD_CONTRACTOR_PURCHASE_ORDERS"
    }),
    async loadData() {
      this.optOutOfErrorHandling();

      this.processing = true;

      try {
        let services = [
          this.loadVisibleAreas(),
          this.loadVisibleSubAreas(),
          this.loadContractors(),
          this.loadAllContractors(),
          this.loadDisciplines(),
          this.loadRequestors(),
          this.$store.dispatch("LOAD_TAGS"),
          this.loadRequestTypes(),
          this.loadRequestSubTypes()
        ];

        if (this.$store.state.curEnvironment?.enablePurchaseOrders == true) {
          services.push(this.loadPurchaseOrders());
        }

        await Promise.all(services);

        if (this.requestTypes.length == 1) {
          this.job.requestType = this.requestTypes[0].value;
        }

        this.job.contractorID = this.curUserAccess.homeContractorID;

        if (this.$store.state.curEnvironment?.enablePurchaseOrders == true) {
          if (this.selectablePurchaseOrders.length == 1) {
            this.job.purchaseOrderID = this.selectablePurchaseOrders[0].id;
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async open(jobType: JobTypes) {
      this.optOutOfErrorHandling();
      this.job.jobTypeID = jobType;
      this.loadData();
      return await this.showDialog!();
    },
    getScopeStepRule(): Array<Function> {
      return [() => !this.scopeStepError];
    },
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveJob();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.basicStepError = !(this.$refs.basicform as HTMLFormElement).validate();
      this.photosStepError = !(this.$refs.photosform as HTMLFormElement).validate();
      this.attachmentsStepError =
        !!(this.$refs.attachmentsform as HTMLFormElement) &&
        !(this.$refs.attachmentsform as HTMLFormElement).validate();

      (this.$refs.scopechangeform as any)?.validate();

      return !(
        this.basicStepError ||
        this.scopeStepError ||
        this.photosStepError ||
        this.attachmentsStepError
      );
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    async releaseJob() {
      this.inlineMessage.message = "";

      this.processing = true;
      this.releasing = true;
      try {
        await this.updateIsDraft(false);
        let saved = await this.saveData();
        if (!saved || !this.job.id) {
          this.releasing = false;
          this.processing = false;
          return false;
        }

        var assignableContractors = [] as ContractorWithTags[];
        if (this.job.jobTypeID == JobTypes.Scaffold) {
          assignableContractors = this.allContractors.filter(x => !!x.isScaffoldCompany);
        } else if (this.job.jobTypeID == JobTypes.Paint) {
          assignableContractors = this.allContractors.filter(x => !!x.isPaintCompany);
        } else if (this.job.jobTypeID == JobTypes.Insulation) {
          assignableContractors = this.allContractors.filter(x => !!x.isInsulationCompany);
        } else if (this.job.jobTypeID == JobTypes.Maintenance) {
          assignableContractors = this.allContractors.filter(x => !!x.isMaintenanceCompany);
        }

        var title = this.$t("scaffold-request-approvals.assign-contractor");
        var contractorID = await showItemSelectionBottomDialog(
          title,
          this.$t("scaffold-request-approvals.contractor-label"),
          [this.rules.required],
          assignableContractors,
          "name",
          "id",
          this.job.contractorID ?? "",
          this.$refs.content as Vue
        );

        // If details is undefined the dialog was cancelled
        if (!contractorID) {
          // Change the value to something else and then back to its current to force a rebind
          this.releasing = false;
          this.processing = false;
          return false;
        }

        await jobService.releaseJobByID(this.job.id, contractorID);

        var snackbarPayload = {
          text: this.$t("job.new.save-success"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.releasing = false;
        this.processing = false;
      }
    },

    //Method used in conjunction with new view dialog.
    async saveJob() {
      this.inlineMessage.message = "";

      this.processing = true;
      this.saving = true;
      try {
        await this.updateIsDraft(true);
        if (!(await this.saveData())) {
          this.processing = false;
          this.saving = false;
          return;
        }

        var snackbarPayload = {
          text: this.$t("job.new.save-success"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.closeDialog!(true);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async saveData(): Promise<boolean> {
      if (!this.validate()) {
        var message = this.$t("job.new.error-message");
        if (this.basicStepError) message += "\n\t- " + this.$t("job.new.steps.details");
        if (this.scopeStepError) message += "\n\t- " + this.$t("job.new.steps.scope-change");
        if (this.photosStepError) message += "\n\t- " + this.$t("job.new.steps.photos");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return false;
      }
      // Save web request
      // The following are numeric values entered as strings due to being text field entry.
      if (this.job.scaffoldID) this.job.scaffoldID = this.job.scaffoldID;

      this.job.tagIDs =
        this.selectedKeywords.length > 0 ? this.selectedKeywords.map(x => x.id!) : undefined;

      // Get the list of selected IWP IDs
      this.job.workPackageIDs = this.selectedIWPs.map(iwp => iwp.id!);

      if (!!this.selectedScaffold) {
        this.job.scaffoldID = this.selectedScaffold.id;
        this.job.scaffoldNumber = this.selectedScaffold.internalNumber;
      } else {
        this.job.scaffoldID = undefined;
      }

      if (!!this.selectedIso) {
        this.job.isoID = this.selectedIso.id;
      } else {
        this.job.isoID = undefined;
      }

      var newJobID = await jobService.addItem(this.job);
      this.job.id = newJobID;

      if (this.allFiles.length) {
        for (let index = 0; index < this.allFiles.length; index++) {
          const file = this.allFiles[index];
          await jobService.uploadJobFile(newJobID, file.name, file.file as Blob);
        }
      }
      if (this.externalLinks.length) {
        for (let index = 0; index < this.externalLinks.length; index++) {
          const newLink = this.externalLinks[index];
          await externalLinkService.addItem({
            jobID: newJobID,
            name: newLink.name,
            address: newLink.address
          } as ExternalLink);
        }
      }
      return true;
    },

    //#region DATA LOADING
    // DOES NOT manage processing or error message logic
    async loadRequestTypes(): Promise<void> {
      if (this.job.jobTypeID == JobTypes.Maintenance) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllMaintenanceRequestTypeDetails();
      } else if (this.job.jobTypeID == JobTypes.Paint) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllPaintRequestTypeDetails();
      } else if (this.job.jobTypeID == JobTypes.Insulation) {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllInsulationRequestTypeDetails();
      } else {
        this.allRequestTypes = await scaffoldRequestTypeHelper.getAllScaffoldRequestTypeDetails();
      }
    },

    // DOES NOT manage processing or error message logic
    async loadRequestSubTypes(): Promise<void> {
      if (this.job.jobTypeID == JobTypes.Maintenance) {
        this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllMaintenanceRequestSubTypesWithParent();
      } else if (this.job.jobTypeID == JobTypes.Paint) {
        this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllPaintRequestSubTypesWithParent();
      } else if (this.job.jobTypeID == JobTypes.Insulation) {
        this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllInsulationRequestSubTypesWithParent();
      } else {
        this.allRequestSubTypes = await scaffoldRequestSubTypeHelper.getAllScaffoldRequestSubTypesWithParent();
      }
    },

    // DOES NOT manage processing or error message logic
    async loadVisibleAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allVisibleAreas = SortItemsWithName(areas);
    },

    // DOES NOT manage processing or error message logic
    async loadVisibleSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allVisibleSubAreas = SortItemsWithName(subAreas);
    },

    // DOES NOT manage processing or error message logic
    async loadContractors(): Promise<void> {
      let contractors = await await contractorService.getAll(false, null, null);
      this.contractors = SortItemsWithName(contractors);
    },
    async loadAllContractors() {
      let allContractors = await contractorService.getAll(false, null, null);
      this.allContractors = SortItemsWithName(allContractors);
    },

    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getAll(false, null, null);
      this.allDisciplines = SortItemsWithName(disciplines);
    },

    // DOES NOT manage processing or error message logic
    async loadRequestors(): Promise<void> {
      let requestors = await personService.getAllActiveRequestors();
      this.allRequestors = SortItemsWithName(
        requestors.map(x => {
          return {
            ...x,
            name: GetPersonName(x)
          };
        })
      );
    },
    //#endregion

    // *** DETAILS ***
    async loadScaffolds(searchString: string) {
      if (!searchString?.length) this.availableScaffolds = [];
      else {
        let scaffolds = await scaffoldService.searchAll(searchString, false);
        this.availableScaffolds = scaffolds.map(x => {
          return {
            ...x,
            description: GetScaffoldDescription(x),
            details: GetScaffoldDetails(x),
            search: `${x.internalNumber} ${x.existingRequestNumber} ${
              x.externalReferenceNumber
            } ${x.subAreaName ?? x.areaName}`
          } as ScaffoldSearchResult;
        });
      }
    },
    async loadScaffoldDesigns() {
      if (!!this.availableDesigns?.length) return;

      let designs = await scaffoldDesignService.getAllReleased();
      this.availableDesigns = designs.map(
        d =>
          ({
            ...d,
            formattedScaffoldNumber: `T-${`00000${d.scaffoldNumber}`.slice(-5)}`
          } as FormattedScaffoldDesign)
      );
    },

    // *** IWPs ***
    async loadWorkPackages(searchString: string) {
      if (!searchString?.length) this.availableIWPs = [];
      else {
        let allIWPs = await workPackageService.searchAll(searchString);
        this.availableIWPs = allIWPs.map(x => {
          return {
            ...x,
            nameCode: `${x.name} | ${x.activityID}`
          };
        });
      }
    },

    // *** ISOS ***
    async loadIsos(searchString: string) {
      if (!searchString?.length) this.availableIsos = [];
      else {
        let isos = await isoService.searchAll(searchString);
        this.availableIsos = isos.map(x => {
          return {
            ...x,
            description: x.name,
            details: GetIsoDetails(x),
            search: `${x.name} ${x.testPackageName} ${x.systemName}`
          } as IsoSearchResult;
        });
      }
    },

    // *** FILES ***
    // *** ATTACHMENTS ***
    // Attachments - Catch the generic "Attachment" objects and pass along to link or file-specific actions
    async openAttachment(item: Attachment) {
      if (!item.canOpenInNew) return;

      if (!!item.file && item.canOpenInNew) {
        await this.openFileInNewWindow(item.file);
      } else if (!!item.link) {
        let url = item.link.address;
        window.open(url, "_blank");
      }
    },
    async openFileInNewWindow(fileData: FileData) {
      let currentProcessing = this.processing;
      this.processing = true;
      if (!fileData.file) {
        return;
      }
      let url = URL.createObjectURL(fileData.file);
      window.open(url, "_blank");
      this.processing = currentProcessing;
    },
    async editAttachment(item: Attachment) {
      if (!!item.link) {
        await this.editLink(item.link);
      } else if (!!item.file && item.file.isPreviewable) {
        await this.editFile(item.file);
      } else if (!!item.file) {
        await this.editNameForFile(item.file);
      }
    },
    async editNameForFile(fileData: FileData) {
      let components = componentsFromFileName(fileData.name);
      let newName = await showTextPromptDialog({
        title: this.$t("attachments.edit-file-name-title"),
        label: this.$t("common.name"),
        rules: [this.rules.required],
        text: components.name
      });
      if (!!newName?.length && newName.toLowerCase() != components.name.toLowerCase()) {
        let newFileName = `${newName}.${components.extension}`;
        var originalFileName = fileData.name;
        if (newFileName.toLowerCase() == originalFileName.toLowerCase()) return;

        var uniqueFileName = confirmUniqueName(newFileName, this.allFiles);

        fileData.name = uniqueFileName;
        this.touchedFileName = fileData.name;

        this.editingFileData = undefined;
      }
    },
    async deleteAttachment(item: Attachment) {
      if (!!item.link) {
        await this.deleteLink(item.link);
      } else if (!!item.file) {
        await this.removeFile(item.file);
      }
    },

    // Links
    // Method to open the dialog for when the user wishes to add a new External Link.
    async openNewExternalLinkDialog() {
      let newLink = await openExternalLinkDetails();
      if (!!newLink) {
        await this.saveNewExternalLink(newLink);
      }
    },
    async saveNewExternalLink(newLink: ExternalLink) {
      newLink.jobID = this.job.id;
      this.externalLinks.push(newLink);

      this.showAttachmentTabPhotoAlert = false;
      this.showPhotoTabAttachmentAlert = false;

      this.touchedFileName = newLink.name ?? "";
    },
    async editLink(link: ExternalLink) {
      let editedLink = await openExternalLinkDetails(link);
      if (!!editedLink) {
        link.name = editedLink.name;
        link.address = editedLink.address;

        this.touchedFileName = link.name ?? "";
      }
    },
    async deleteLink(link: ExternalLink) {
      this.externalLinks.splice(this.externalLinks.indexOf(link), 1);

      this.touchedFileName = link.name ?? "";
    },

    // Files & Photos
    fileRowClassName(item: any): string {
      return item.name == this.touchedFileName ? "fd-selected-table-row-background" : "";
    },
    photosRowClicked(e: Event, data: any) {
      this.viewPhoto(data.item);
    },
    async selectFile() {
      (this.$refs.addFileButton as any).click();
    },
    async fileInputChanged(v: any) {
      if (!v.target?.files.length) return;
      await this.selectNewFile(v.target.files[0]);
    },
    async selectNewFile(originalFile: File | null | undefined) {
      if (!originalFile) return;
      var fileData = await this.optimizedFileDataForUpload(originalFile, this.allFiles);
      if (!fileData) return;

      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        this.allFiles.push(fileData);

        this.touchedFileName = fileData.name;
        this.showPhotoTabAttachmentAlert = this.step == this.photosStep && !fileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.step == this.attachmentsStep && fileData.isPhoto == true;
      }
    },
    editFile(fileData: FileData | null | undefined) {
      if (!fileData || !fileData.isPreviewable) return;

      this.editingFileData = fileData;
      this.imageName = fileData.name;
      this.editImageSource = this.covertFileToDataURL(fileData.file);
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.allFiles);

        this.allFiles.push(this.newFileData);

        this.touchedFileName = this.newFileData.name;
        this.showPhotoTabAttachmentAlert =
          this.step == this.photosStep && !this.newFileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.step == this.attachmentsStep && this.newFileData.isPhoto == true;

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.allFiles.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.touchedFileName = this.editingFileData.name;
        this.showPhotoTabAttachmentAlert =
          this.step == this.photosStep && !this.editingFileData.isPhoto;
        this.showAttachmentTabPhotoAlert =
          this.step == this.attachmentsStep && this.editingFileData.isPhoto == true;

        this.editingFileData = undefined;
      }
    },
    viewPhoto(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(fileData: FileData) {
      var fileIndex = this.allFiles.indexOf(fileData);
      if (fileIndex == undefined) return;

      this.allFiles.splice(fileIndex, 1);
      this.touchedFileName = fileData.name;
      this.showAttachmentTabPhotoAlert = false;
      this.showPhotoTabAttachmentAlert = false;
    },
    attachmentRowClicked(e: Event, data: any) {
      this.openAttachment(data.item);
    }
  },

  watch: {
    search(this: any, newValue: string) {
      this.hasLoaded = false;
      // If the user kept typing/changed the search before the service call, cancel the previous call in preparation of the new one
      if (this.timer) clearTimeout(this.timer);

      if (!newValue?.length) {
        this.searchItems = [];
        return;
      }

      var obj = this;
      // Delay the service call to allow the user to keep typing if they choose before making a server call
      this.timer = setTimeout(async function() {
        obj.loading = true;
        try {
          obj.loadWorkPackages(newValue);
        } finally {
          obj.timer = null;
          obj.loading = false;
          obj.hasLoaded = true;
        }
      }, 1500);
    },
    selectedScaffold: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.job.scaffoldID = undefined;
        this.job.areaID = undefined;
        this.job.subAreaID = undefined;
        this.job.specificWorkLocation = undefined;
        this.dataSetByScaffold = false;
      } else if (!!newValue) {
        let scaffold: ScaffoldSearchResult = newValue;
        this.job.scaffoldID = scaffold.id;
        this.job.scaffoldNumber = scaffold.internalNumber;

        this.job.areaID = scaffold.areaID;
        this.job.subAreaID = scaffold.subAreaID;
        this.job.specificWorkLocation = scaffold.specificWorkLocation;
        this.dataSetByScaffold = true;
        this.dataSetByDesign = false;
      }
    },
    selectedIso: async function(newValue, oldValue) {
      if (!!oldValue && !newValue) {
        this.job.isoID = undefined;
      } else if (!!newValue) {
        let iso: IsoSearchResult = newValue;
        this.job.isoID = iso.id;
      }
    },
    "job.requestType": function(newValue, oldValue) {
      if (this.requestSubTypes.length == 1) {
        this.job.requestSubType = this.requestSubTypes[0].value;
      }
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (newValue == 1) this.selectedScaffold = null;
    },
    "job.areaID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.job.subAreaID,
          this.subAreas.map(x => x.id)
        )
      ) {
        this.job.subAreaID = null;
      }
    },
    "job.contractorID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.job.disciplineID,
          this.disciplines.map(x => x.id)
        )
      ) {
        this.job.disciplineID = null;
      }

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.job.requestingEmployeeID,
          this.requestors.map(x => x.id)
        )
      ) {
        this.job.requestingEmployeeID = undefined;
      }

      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.job.areaID,
          this.areas.map(x => x.id)
        )
      ) {
        this.job.areaID = null;
      }
      if (
        !valueInArray(
          this.job.subAreaID,
          this.subAreas.map(x => x.id)
        )
      ) {
        this.job.subAreaID = null;
      }
      if (this.$store.state.curEnvironment?.enablePurchaseOrders == true) {
        if (
          !!this.job.purchaseOrderID &&
          this.selectablePurchaseOrders.findIndex(x => x.id == this.job.purchaseOrderID) == -1
        ) {
          this.job.purchaseOrderID = null;
        }

        if (!this.job.purchaseOrderID && this.selectablePurchaseOrders.length == 1) {
          this.job.purchaseOrderID = this.selectablePurchaseOrders[0].id;
        }
      }
    },
    "job.disciplineID": function(newValue, oldValue) {
      // If there was a selected value, confirm it's in the new data.  If not, clear out the value
      if (
        !valueInArray(
          this.job.requestingEmployeeID,
          this.requestors.map(x => x.id)
        )
      ) {
        this.job.requestingEmployeeID = undefined;
      }
    }
  },

  created: async function() {}
});

export default JobNewDialog;

export async function createNewJob(category: JobTypes, options?: {}): Promise<boolean> {
  let dialog = createDialog(JobNewDialog);
  dialog.optOutOfErrorHandling();
  return await dialog.open(category);
}

